import {FormikHelpers} from "formik";

const unknownError = "Przepraszamy, wystąpił nieoczekiwany błąd. Spróbuj ponownie później.";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IFormikPostCallbacks<TValues, Resp> {
    onFieldError?: () => void;
    onNonFieldError?: (nonFieldErrors: string) => void;
    onSuccess?: (res: Resp) => void;
}
export const handleFormikPost = <TValues, TResp>(
    postPromise: Promise<TResp>,
    formikHelpers: FormikHelpers<TValues>,
    callbacks: IFormikPostCallbacks<TValues, TResp>
) => {
    return postPromise
        .then((res) => {
            if (res) {
                callbacks.onSuccess?.(res);
                return res;
            }
        })
        .catch((error) => {
            if (error.appError?.fieldErrors) {
                // error 400 - form error
                formikHelpers.setErrors(error.appError.fieldErrors);
                error.appError.nonFieldErrors.length > 0 && callbacks.onNonFieldError?.(error.appError.nonFieldErrors.join(", "));

                callbacks.onFieldError?.();
            } else {
                callbacks.onNonFieldError?.(unknownError);
            }
        })
        .finally(() => formikHelpers.setSubmitting(false));
};
