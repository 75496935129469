export enum PricesViewType {
    FLATS = "mieszkania",
    HOUSES = "domy",
    ALL = "nieruchomości"
}

export const getTypeForFetch = (type: PricesViewType) => {
    switch (type) {
        case PricesViewType.FLATS:
            return "Mieszkania";
        case PricesViewType.HOUSES:
            return "Domy";
        default:
            return "";
    }
};

export const getWhichLabelFromPricesViewType = (type: PricesViewType) => {
    switch (type) {
        case PricesViewType.FLATS:
            return "mieszkań";
        case PricesViewType.HOUSES:
            return "domów";
        default:
            return "";
    }
};
