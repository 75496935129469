import type {Dispatch, UnknownAction} from "redux";

import {createFormActions, IFormValuesAction} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {fetchAllListsType, SearchTab} from "../../search/actions/fetch_search_all_action";
import {fetchRegionList, IRegionListResponse} from "../../search/actions/fetch_search_regions_action";
import {fetchVendorList, IVendorListResponse} from "../../search/actions/fetch_search_vendors_action";
import {searchFormTypes} from "../../search/actions/search_form_actions";
import {IVendorSearchFormValuesType} from "../components/SearchVendor";

export const vendorListSearchFormActions = createFormActions<IVendorSearchFormValuesType>(searchFormTypes);

export const fetchAllVendorSearchLists =
    (searchInput: string) =>
    (dispatch: Dispatch<IFormValuesAction<unknown> | UnknownAction>): Promise<SearchTab> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            dispatch({type: fetchAllListsType.start});
            // `exclude_unpublished_vendor_listings: 1` - `1` means just true
            const regionsPromise = dispatch(fetchRegionList(searchInput, {exclude_unpublished_vendor_listings: 1})).then((res: IRegionListResponse) => {
                if (res && res.results && !isEmpty(res.results)) {
                    // resolve main promise
                    resolve(SearchTab.Regions); // SWITCH to regions
                    return SearchTab.Regions; // regions found
                }
            });
            const vendorsPromise = dispatch(fetchVendorList(searchInput)).then(async (res: IVendorListResponse) => {
                if (res && res.results && !isEmpty(res.results)) {
                    const regionTab = await regionsPromise;
                    if (regionTab != null) {
                        // regions found so do not resolve main promise
                        return true; // vendors found
                    }
                    resolve(SearchTab.Vendors); // SWITCH to vendors
                    return SearchTab.Vendors;
                }
            });

            // hit places only when all previous lists are empty
            if ((await regionsPromise) != null) {
                dispatch({type: fetchAllListsType.success});
                // regions found so do not resolve main promise
                return;
            }

            if ((await vendorsPromise) != null) {
                dispatch({type: fetchAllListsType.success});
                // vendors found so do not resolve main promise
                return;
            }
            // resolve main promise
            resolve(SearchTab.Vendors); // SWITCH to vendors

            dispatch({type: fetchAllListsType.success});
            // TODO: timeout to reject
        });
    };

export const resetClientOnlyVendorSearchFormAtRoute = () => async (dispatch: Dispatch<IFormValuesAction<unknown>>, getState: () => IRPStore) => {
    const regions = getState().vendor.list.region;
    const tabType = regions && regions.length > 0 ? SearchTab.Regions : null;
    return dispatch(
        vendorListSearchFormActions.replace({
            search: {
                label: "",
                tabType: tabType,
                regions: getState().vendor.list.region
            }
        })
    );
};
